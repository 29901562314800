<template>
  <div>
    <list v-bind="props" />
  </div>
</template>

<script>
import List from '@/components/admin/List'
import Modal from '@/views/admin/projects/modal'

export default {
  components: {
    List
  },
  data () {
    return {
      props: {
        plural: 'projects',
        singular: 'project',
        fetchUrl: 'projects',
        actionUrl: 'project',
        fields: [
          {
            key: 'id',
            class: 'idColumn'
          },
          {
            key: 'name',
          },
          {
            key: 'client.name',
            label: 'Client',
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'actions'
          }
        ],
        modalComponent: Modal
      }
    }
  }
}
</script>
