<template>
  <b-container fluid>
    <h1>Time Tracking</h1>

    <b-row class="monthSelector">
      <b-col class="text-right">
        <button @click="previousMonth" class="navigationButton">
          <b-icon-caret-left-fill></b-icon-caret-left-fill>
        </button>
      </b-col>

      <b-col cols="3" class="text-center navDate">
        {{ $dayjs.utc(displayDate).format('MMMM YYYY') }}
      </b-col>

      <b-col class="text-left">
        <button @click="nextMonth" class="navigationButton" v-if="enableGoNext">
          <b-icon-caret-right-fill></b-icon-caret-right-fill>
        </button>
      </b-col>
    </b-row>

    <b-row class="search">
      <b-col>
        <div class="search-bar"><b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search" /></div>
        <!-- <div><button class="button success" @click="changeView()" >Show by {{ (showByPerson) ? 'project' : 'person' }}</button></div> -->
      </b-col>
    </b-row>

    <b-table
      bordered
      hover
      headVariant="light"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="items"
      :fields="fields"
      :filter="filter"
      :tbody-tr-class="rowClass"
      :per-page="perPage"
      :current-page="currentPage"
      @filtered="onFiltered"
      @row-clicked="toggleDetails"
      show-empty
    >

      <template #row-details="row">
        <b-card>
          <b-container v-for="(project, key) in row.item.projects" :key="key" class="itemDetailsContainer" fluid>
            <div class="itemDetailsTitle">{{ project.name }} | {{ project.totalHours }} hours</div>
            <b-row class="itemDetailsInfo" v-for="(timetracking, key) in sortByDate(project.timetracking)" :key="key">
              <b-col>{{ timetracking.description }}</b-col>
              <b-col cols="3">{{ $dayjs.utc(timetracking.date).format('MMM DD YYYY, dddd') }}</b-col>
              <b-col cols="1" class="text-right">{{ timetracking.time }}</b-col>
            </b-row>
          </b-container>
        </b-card>
      </template>

    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination>
  </b-container>
</template>

<script>
export default {
  data () {
    return {
      displayDate: this.$dayjs.utc(),
      maxDate: this.$dayjs.utc().startOf('day').weekday(-7),
      perPage: 20,
      currentPage: 1,
      filter: null,
      isBusy: false,
      totalRows: 0,
      sortBy: 'firstname',
      sortDesc: false,
      fields: [
        {
          key: 'firstname',
          sortable: true
        },
        {
          key: 'lastname',
          sortable: true
        },
        {
          key: 'hours',
          label: 'Hours',
          sortable: true,
        }
      ],
      items: [],
      projects: [],
      showByPerson: true
    }
  },
  computed: {
    enableGoNext: function () {
      if(this.$dayjs.utc(this.displayDate).isSameOrBefore(this.maxDate)) {
        return true
      } else {
        return false
      }
    },
  },
  created () {
    this.fetchProjects()
    this.fetchHours()
  },
  methods: {
    sortByDate: function (timeArray) {
      const tmpArray = [...timeArray].sort( ( a, b) => {
        return new Date(a.date) - new Date(b.date)
      })
      return tmpArray
    },
    
    fetchHours() {
      const self = this
      const loader = this.$loading.show()

      this.$http.get('/timetracking/all/' + this.displayDate.get('year') + '/' + this.displayDate.get('month'))
      .then(function (response) {
        loader.hide()
        self.items = response.data
        self.totalRows = self.items.length
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    fetchProjects() {
      const self = this
      const loader = this.$loading.show()

      this.$http.get('/projects')
      .then(function (response) {
        loader.hide()
        self.projects = response.data
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    previousMonth () {
      this.displayDate = this.$dayjs.utc(this.displayDate).subtract(1, 'month')
      this.fetchHours()
    },

    nextMonth () {
      this.displayDate = this.$dayjs.utc(this.displayDate).add(1, 'month')
      this.fetchHours()
    },

    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    rowClass (item, type) {
      if (item && type === 'row') {
        return 'table-row'
      } else {
        return null
      }
    },

    toggleDetails(row) {
      if(row._showDetails){
        this.$set(row, '_showDetails', false)
      } else {
        this.items.forEach(item => {
          this.$set(item, '_showDetails', false)
        })
        this.$nextTick(() => {
          this.$set(row, '_showDetails', true)
        })
      }
    },
  }
}
</script>

<style lang="scss">
.table-row {
  cursor: pointer;
}

.b-table-details {
  background-color: #e9ecef;
}

.itemDetailsContainer {
  .itemDetailsTitle {
    font-weight: bold;
    text-align: center;
    margin: 10px -15px;
    overflow: hidden;

    &:before,
    &:after {
      background-color: #818181;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &:before {
      right: 0.5em;
      margin-left: -50%;
    }

    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }

  .itemDetailsInfo {
    margin: 10px -15px;

    &:not(:last-child) {
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 10px;
    }
  }
}
</style>
