<template>
  <div v-if="invoice && invoice.number" id="invoice-container">
    <b-container fluid class="invoice">
      <b-row class="section invoice-header">
        <b-col>
          <div><label>{{ labels[language].sender_name }}:</label> {{ invoice.from_name }}</div>
          <div v-if="invoice.from_address"><label>{{ labels[language].sender_address }}:</label> {{ invoice.from_address }}</div>
          <div v-if="invoice.from_zipcode"><label>{{ labels[language].sender_zip_code }}:</label> {{ invoice.from_zipcode }}</div>
          <div v-if="invoice.from_tax_id"><label>{{ labels[language].sender_tax_id }}:</label> {{ invoice.from_tax_id }}</div>
        </b-col>

        <b-col class="invoice-data">
          <div class="invoice-number">{{ ('00000000'+ invoice.number ).slice(-8) }}</div>
          <div class="invoice-date"><label>{{ labels[language].issue_date }}:</label> {{ $dayjs.utc(invoice.date).format('DD-MM-YYYY') }}</div>
        </b-col>
      </b-row>

      <b-row class="section invoice-period">
        <b-col>
          <div><label>{{ labels[language].period_from }}:</label> {{ $dayjs.utc(invoice.period_from).format('DD-MM-YYYY') }}</div>
        </b-col>

        <b-col>
          <div><label>{{ labels[language].period_to }}:</label> {{ $dayjs.utc(invoice.period_to).format('DD-MM-YYYY') }}</div>
        </b-col>

        <b-col>
          <div><label>{{ labels[language].due_date }}:</label> {{ $dayjs.utc(invoice.due_date).format('DD-MM-YYYY') }}</div>
        </b-col>
      </b-row>

      <b-row class="section invoice-recipient">
        <b-col>
          <div><label>{{ labels[language].recipient_name }}:</label> {{ invoice.to_name }}</div>
          <div><label>{{ labels[language].recipient_tax_id }}:</label> {{ invoice.to_tax_id }}</div>
        </b-col>

        <b-col>
          <div><label>{{ labels[language].recipient_address }}:</label> {{ invoice.to_address }}</div>
          <div><label>{{ labels[language].recipient_zip_code }}:</label> {{ invoice.to_zipcode }}</div>
        </b-col>
      </b-row>

      <b-row class="section items">
        <b-col>
          <b-row class="header">
            <b-col>
              {{ labels[language].description }}
            </b-col>

            <b-col cols="2" class="currency">
              {{ labels[language].amount }} 
            </b-col>
          </b-row>

          <b-row v-for="(item, index) in invoice.items" :key="index">
            <b-col v-if="item.price > 0">
              <div>{{ getDescription(item.description) }}</div>
            </b-col>

            <b-col cols="2" class="currency" v-if="item.price > 0">
              <div>USD {{ item.price.toFixed(2) }}</div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="section section-notes">
        <b-col cols="7">
          <div v-if="invoice.notes"><label>{{ labels[language].notes }}:</label></div>
          <div v-if="invoice.notes">{{ invoice.notes }}</div>
        </b-col>

        <b-col class="currency">
          <label>{{ labels[language].total }}:</label>
          <label class="account-line" v-if="invoice.payment_account && invoice.client_id == 1">{{ labels[language].payment_account }}:</label>
        </b-col>

        <b-col class="currency">
          <div>USD {{ invoice.total }}</div>
          <div class="account-line" v-if="invoice.payment_account && invoice.client_id == 1">{{ getPaymentAccount(invoice.payment_account) }}</div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hash: this.$route.params.hash,
      invoice: null,
      services: [],
      extraOptions: [
        { value: null, text: '' },
        { value: 'equipment', text: 'Pago de equipo' },
        { value: 'referral', text: 'Bono por referido' },
      ],
      paymentOptions: [
        { value: null, text: '' },
        { value: 'payoneer', text: 'Payoneer' },
        { value: 'transferwise', text: 'TransferWise' },
        { value: 'bankaccount', text: 'Cuenta bancaria'}
      ],
      language: 'en',
      labels: {
        en: {
          sender_name: 'Name',
          sender_address: 'Address',
          sender_zip_code: 'Zip code',
          sender_tax_id: 'Tax ID',
          issue_date: 'Issue date',
          period_from: 'Period from',
          period_to: 'Period to',
          due_date: 'Due date',
          recipient_name: 'Name',
          recipient_tax_id: 'Tax ID',
          recipient_address: 'Address',
          recipient_zip_code: 'Zip code',
          description: 'Description',
          amount: 'Amount',
          notes: 'Notes',
          total: 'Total',
          payment_account: 'Payment method',
        },
        es: {
          sender_name: 'Nombre',
          sender_address: 'Dirección',
          sender_zip_code: 'Código postal',
          sender_tax_id: 'ID impositivo',
          issue_date: 'Fecha de emisión',
          period_from: 'Período desde',
          period_to: 'Período hasta',
          due_date: 'Vencimiento',
          recipient_name: 'Nombre',
          recipient_tax_id: 'RUT',
          recipient_address: 'Dirección',
          recipient_zip_code: 'Código postal',
          description: 'Descripción',
          amount: 'Importe',
          notes: 'Notas',
          total: 'Total',
          payment_account: 'Método de pago',
        }
      }
    }
  },
  created () {
    this.fetchInvoice()
  },
  methods: {
    fetchInvoice() {
        const self = this
        const loader = this.$loading.show();

      this.$http.get('/invoice/' + this.hash)
      .then(function (response) {
        loader.hide()

        self.invoice = response.data

        const items = JSON.parse(self.invoice.items)

        if(self.invoice.client_id == 1) {
          self.language = 'es'
        }

        let services = []
        let subtotal = 0

        items.forEach(item => {
          if(!item.canDelete) {
            services.push({
              description: item.description,
              price: parseFloat(item.price)
            })

            subtotal += parseFloat(item.price)
          }
        })

        if(parseFloat(self.invoice.total) != parseFloat(subtotal)) {
          services[services.length - 1].price += parseFloat(self.invoice.total) - parseFloat(subtotal)
        }

        self.invoice.items = services
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    getDescription(description) {
      const result = this.extraOptions.filter(obj => {
        return obj.value === description
      })

      if(result.length) {
        return result[0].text
      } else {
        return description
      }
    },

    getPaymentAccount(payment_account) {
      const result = this.paymentOptions.filter(obj => {
        return obj.value === payment_account
      })

      if(result.length) {
        return result[0].text
      } else {
        return payment_account
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#invoice-container {
  .invoice {
    width: 850px;
    border: double #000000;

    label {
      font-weight: bold;
      margin: 0 !important;
    }

    .section {
      border-bottom: 1px solid #bcbcbc;
      padding: 10px;
    }

    .items {
      min-height: 200px;

      .header {
        font-weight: bold;
        font-size: 0.9em;
      }

      .service_name {
        text-transform: lowercase;
      }

      .add-new-item {
        text-align: center;
        margin: 15px 0;

        .b-icon {
          cursor: pointer;
        }
      }

      .remove-item {
        padding-top: 10px;
      }
    }

    .invoice-header {
      .invoice-data {
        border-left: 2px solid #bcbcbc;
        text-align: right;
      }

      .invoice-number {
        font-size: 1.4em;
        font-weight: bold;
      }
    }

    .currency {
      text-align: right;
    }

    .notes {
      display: flex;
      padding-top: 10px;

      label {
        display: inline-flex !important;
        width: 100px;
        padding-top: 6px;
      }
    }

    .account-line {
      padding-top: 15px;
    }
  }
}
</style>
