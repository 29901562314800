<template>
  <b-container fluid>
    <h1>Invoices</h1>
    <b-row>
      <b-col>
        <invoice @newInvoice="addNewInvoice" />
      </b-col>
    </b-row>

    <h2>Previous invoices</h2>

    <b-row class="invoice-header">
      <b-col cols="1">Status</b-col>
      <b-col cols="2">Due Date</b-col>
      <b-col class="text-right">Amount</b-col>
      <b-col cols="2" class="text-center actions">Actions</b-col>
    </b-row>

    <div v-if="invoices.length">
      <b-row v-for="invoice in invoices" :key="invoice.id" class="invoice-line">
        <b-col cols="1"><span class="status" :class="invoiceStatus(invoice.status, invoice.due_date)">{{ invoiceStatus(invoice.status, invoice.due_date) }}</span></b-col>
        <b-col cols="2">{{ $dayjs.utc(invoice.due_date).format('DD-MM-YYYY') }}</b-col>
        <b-col class="text-right">USD {{ parseFloat(invoice.total).toFixed(2) }}</b-col>
        <b-col cols="2" class="text-center actions">
          <a :href="'https://res.cloudinary.com/' + cloudinary_bucket + '/image/upload/Invoices/' + invoice.hash + '.pdf'" download target="_blank"><b-icon-cloud-arrow-down-fill font-scale="1.6" /></a>
          <router-link :to="'/invoice/' + invoice.hash" target="_blank"><b-icon-eye font-scale="1.6" /></router-link>
        </b-col>
      </b-row>
    </div>

    <div v-else>You have no previous invoices.</div>
  </b-container>
</template>

<script>
import Invoice from '@/components/invoice'

export default {
  components: {
    Invoice
  },
  data () {
    return {
      invoices: [],
      cloudinary_bucket: process.env.VUE_APP_CLOUDINARY_NAME
    }
  },
  created () {
    this.fetchInvoices()
  },
  methods: {
    fetchInvoices () {
      const self = this
      const loader = this.$loading.show();

      this.$http.get('/self/invoices')
      .then(function (response) {
        loader.hide()
        self.invoices = response.data
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    invoiceStatus (status, due_date) {
      if(status == 1) {
        return 'paid'
      } else {
        const today = this.$dayjs().utc()

        if(this.$dayjs.utc(today).isSameOrBefore(due_date)) {
          return 'pending'
        } else {
          return 'overdue'
        }
      }
    },

    addNewInvoice(invoice) {
      this.invoices.unshift(invoice)
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-header {
  padding: 5px 0;
  padding-top: 8px;
  border-bottom: 1px solid #bcbcbc;
  font-size: 0.9em;
  font-weight: bold;
}

.invoice-line {
  padding: 5px 0;
  padding-top: 8px;
  border-bottom: 1px solid #bcbcbc;
}

.status {
  border-radius: 5px !important;
  border: 0 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding: 3px !important;
  width: 60px;
  display: block;
  text-align: center;

  &.paid {
    background-color: #1cab3d !important;
    color: white;
  }

  &.pending {
    background-color: #ffda55 !important;
    color: black;
  }

  &.overdue {
    background-color: #e10f0f !important;
    color: white;
  }
}

.actions {
  .b-icon {
    margin: 0 5px;
  }
}
</style>
