<template>
  <b-container fluid class="list">
    <h1>{{ plural }}</h1>

    <b-row class="search">
      <b-col>
        <div class="search-bar" id="tooltip-search-invoices">
          <b-input-group>
            <b-form-input id="filter-input" v-model="filter" placeholder="Type to Search" @keyup.enter="searchItems()" class="searchInput" />

            <b-input-group-append>
              <b-button variant="danger" @click="clearSearch" v-if="filter"><b-icon-x /></b-button>
              <b-button variant="secondary"><b-icon-search /></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <button class="button success new-item-button" @click="showModal(true)" v-if="canAdd">New {{ singular }}</button>
        <slot name="listActions" />
      </b-col>
    </b-row>

    <b-tooltip target="tooltip-search-invoices" triggers="hover" variant="info" placement="left">
      <slot name="tooltip" />
    </b-tooltip>

    <b-table
      bordered
      hover
      headVariant="light"
      sort-by="archived"
      :sort-desc="false"
      :items="items"
      :fields="fields"
      :tbody-tr-class="rowClass"
      :per-page="perPage"
      show-empty
    >
      <template #cell(id)="row" className="idColumn">
        {{ ('0000'+ row.item.id ).slice(-4) }}
      </template>

      <template #cell(actions)="row">
        <div class="actions-container" v-if="row.item.archived">
          <div class="action-block" @click="showArchivedConfirmation(row.item)"><b-icon-arrow-repeat /></div>
        </div>

        <div class="actions-container" v-else>
          <div class="action-block" @click="showModal(false, row.item)"><b-icon-eye-fill /></div>
          <div class="action-block" @click="showModal(true, row.item)"><b-icon-pencil /></div>
          <div class="action-block" @click="showDeleteConfirmation(row.item)"><b-icon-trash /></div>
        </div>
      </template>

      <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
        <slot :name="slotName" v-bind="scope" />
      </template>

      <template #empty>
        You have no {{ plural }}.
      </template>

      <template #emptyfiltered>
        No {{ singular }} matches your search criteria.
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @change="changePage"
      aria-controls="my-table"
      align="center"
    ></b-pagination>

    <b-modal ref="modal" :title="singular + ' info'" size="xl" id="itemInfoModal" centered>
      <component v-bind:is="modalComponent" :itemProp.sync="selectedItem" :editable="selectedItemEditable" @ready="checkReady" />

      <template #modal-footer>
        <button class="button" @click="hideModal()" v-if="!selectedItemEditable">Close</button>

        <button class="button warning" @click="cancel()" v-if="selectedItemEditable">Cancel</button>

        <button class="button success" @click="save()" v-if="selectedItemEditable" :disabled="!canSave">Save</button>
      </template>
    </b-modal>

    <b-modal ref="deleteModal" title="Confirmation" size="md" id="itemDeleteModal" centered>
      Are you sure you want to delete the {{ this.singular }} #{{ ('0000'+ selectedItem.id ).slice(-4) }}?

      <template #modal-footer>
        <button class="button warning" @click="hideDeleteConfirmation()">Cancel</button>

        <button class="button success" @click="deleteItem()">Delete</button>
      </template>
    </b-modal>

    <b-modal ref="archivedModal" title="Confirmation" size="md" id="itemArchivedModal" centered>
      Are you sure you want to restore the {{ this.singular }} #{{ ('0000'+ selectedItem.id ).slice(-4) }}?

      <template #modal-footer>
        <button class="button warning" @click="hideArchivedConfirmation()">Cancel</button>

        <button class="button success" @click="restoreItem()">Restore</button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  props: {
    plural: {
      type: String,
      required: true,
    },
    singular: {
      type: String,
      required: true,
    },
    fetchUrl: {
      type: String,
      required: true,
    },
    actionUrl: {
      type: String,
      required: true,
    },
    canAdd: {
      type: Boolean,
      default: true
    },
    fields: {
      type: Array,
      required: true,
    },
    modalComponent: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      sortDesc: false,
      filter: null,
      isBusy: false,
      items: [],
      newItem: false,
      selectedItem: {},
      selectedItemOriginal: {},
      selectedItemEditable: false,
      totalRows: 0,
      canSave: false
    }
  },
  created () {
    this.fetchItems()
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    fetchItems () {
      const self = this
      const loader = this.$loading.show()

      this.$http.get('/' + this.fetchUrl + '/' + this.currentPage)
      .then(function (response) {
        loader.hide()
        self.items = response.data.rows
        self.totalRows = response.data.count
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    searchItems () {
      if(!this.filter) {
        this.currentPage = 1
        this.fetchItems()
        return
      }

      const self = this
      const loader = this.$loading.show()
      const page = this.searched ? this.currentPage : 1

      this.$http.post('/'+ this.fetchUrl + '/' +  page + '/' + this.filter)
      .then(function (response) {
        loader.hide()
        self.items = response.data.rows
        self.totalRows = response.data.count
        self.searched = true
      })
      .catch(function (error) {
        loader.hide()
        self.searched = false
        console.log(error)
      })
    },

    clearSearch () {
      this.filter = null
      this.currentPage = 1
      this.searched = false
      this.fetchItems()
    },

    changePage (page) {
      this.currentPage = page
      if(this.filter) {
        this.searchItems()
      } else {
        this.fetchItems()
      }
    },

    rowClass (item, type) {
      if (item && type === 'row') {
        return 'table-row'
      } else {
        return null
      }
    },

    checkReady (value) {
      this.canSave = value
    },

    showModal (editBool, item) {
      this.$refs['modal'].show()

      if(item) {
        this.selectedItemOriginal = JSON.parse(JSON.stringify(item))
        this.selectedItem = item
      } else {
        this.selectedItemOriginal = {}
        this.selectedItem = {}
        this.newItem = true
      }

      this.selectedItemEditable = editBool
    },

    hideModal () {
      this.$refs['modal'].hide()
      this.selectedItem = {}
      this.selectedItemOriginal = {}
      this.selectedItemEditable = false
      this.newItem = false
    },

    showDeleteConfirmation (item) {
      this.$refs['deleteModal'].show()
      this.selectedItem = item
    },

    hideDeleteConfirmation () {
      this.$refs['deleteModal'].hide()
      this.selectedItem = {}
    },

    showArchivedConfirmation (item) {
      this.$refs['archivedModal'].show()
      this.selectedItem = item
    },

    hideArchivedConfirmation () {
      this.$refs['archivedModal'].hide()
      this.selectedItem = {}
    },

    cancel () {
      Object.assign(this.selectedItem, this.selectedItemOriginal);
      this.hideModal()
    },

    save () {
      const self = this
      const loader = this.$loading.show()

      if(this.newItem) { // NEW ITEM
        this.$http.post('/' + this.actionUrl, this.selectedItem)
        .then(function () {
          loader.hide()

          self.$bvToast.toast(self.singular.charAt(0).toUpperCase() + self.singular.slice(1) + ' created successfully.', {
            title: 'Woohoo!',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: true
          })

          self.clearSearch()

          self.hideModal()
        })
        .catch(function (error) {
          loader.hide()
          console.log(error)
        })
      } else { // UPDATE ITEM
        this.$http.put('/' + this.actionUrl + '/' + this.selectedItem.id, this.selectedItem)
        .then(function () {
          loader.hide()

          self.$bvToast.toast(self.singular.charAt(0).toUpperCase() + self.singular.slice(1) + ' saved successfully.', {
            title: 'Woohoo!',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: true
          })

          self.clearSearch()

          self.hideModal()
        })
        .catch(function (error) {
          loader.hide()
          console.log(error)
        })
      }
    },

    deleteItem () {
      const self = this
      const loader = this.$loading.show()

      this.$http.delete('/' + this.actionUrl + '/' + this.selectedItem.id)
      .then(function () {
        loader.hide()

        self.$bvToast.toast(self.singular.charAt(0).toUpperCase() + self.singular.slice(1) + ' deleted successfully.', {
          title: 'Woohoo!',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: true
        })

        self.clearSearch()

        self.hideDeleteConfirmation()
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    restoreItem () {
      const self = this
      const loader = this.$loading.show()

      this.$http.put('/' + this.actionUrl + '/restore/' + this.selectedItem.id)
      .then(function () {
        loader.hide()

        self.$bvToast.toast(self.singular.charAt(0).toUpperCase() + self.singular.slice(1) + ' restored successfully.', {
          title: 'Woohoo!',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: true
        })

        self.clearSearch()

        self.hideArchivedConfirmation()
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },
  }
}
</script>

<style lang="scss">
.list {
  .search {
    margin-bottom: 10px;

    .col {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .search-bar {
        flex: 1 1;
      }

      .new-item-button {
        margin-left: 20px;
      }
    }
  }

  .searchInput {
    margin: 0px;
  }

  .idColumn {
    width: 70px;
    text-align: center;
  }

  .newItem {
    text-align: center;
  }

  .actions-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    .action-block {
      width: 35px;

      a {
        color: black;
      }

      .b-icon {
        width: 24px;
        height: 24px;
        vertical-align: middle;

        &:hover {
          color: #767676;
        }
      }
    }
  }
}

#itemInfoModal {
  .modal-header {
    text-transform: capitalize;
  }
}
</style>
