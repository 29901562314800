<template>
  <div>
    <list v-bind="props" ref="list">
      <div slot="tooltip">
        You can use wildcards such as <strong>:paid</strong>, <strong>:unpaid</strong> or <strong>:overdue</strong>.
      </div>

      <div slot="listActions" v-if="(this.$route.fullPath.substring(this.$route.fullPath.lastIndexOf('/') + 1) == 'clients') ? false : true">
        <button class="button success new-item-button" @click="showPayAll">Mark as paid</button>
      </div>

      <template #cell(number)="row">
        {{ ('00000000'+ row.item.number ).slice(-8) }}
      </template>

      <template #cell(status)="row">
        <span class="status" :class="status(row.item.status, row.item.due_date)">{{ status(row.item.status, row.item.due_date) }}</span>
      </template>

      <template #cell(period_from)="row">
        <div class="text-right">{{ $dayjs.utc(row.item.period_from).format('MMMM YYYY') }}</div>
      </template>

      <template #cell(due_date)="row">
        <div class="text-right">{{ $dayjs.utc(row.item.due_date).format('DD MMMM YYYY') }}</div>
      </template>

      <template #cell(total)="row">
        USD {{ parseFloat(row.item.total).toFixed(2) }}
      </template>

      <template #cell(actions)="row">
        <div class="actions-container">
          <div class="action-block" @click="regenerateInvoice(row.item.hash)"><b-icon-arrow-clockwise /></div>
          <div class="action-block"><a :href="'https://res.cloudinary.com/' + cloudinary_bucket + '/image/upload/v1/Invoices/' + row.item.hash + '.pdf'" @click.prevent="downloadItem('https://res.cloudinary.com/' + cloudinary_bucket + '/image/upload/v1/Invoices/' + row.item.hash + '.pdf', row.item.from_name + ' | ' + $dayjs.utc(row.item.period_from).format('MMMM YYYY'))"><b-icon-cloud-arrow-down-fill /></a></div>
          <div class="action-block" @click="showModal(false, row.item)"><b-icon-eye-fill /></div>
          <div class="action-block" @click="showConfirmation(row.item)"><b-icon-trash /></div>
        </div>
      </template>
    </list>

    <b-modal ref="modal" :title="'Invoice ' + ('00000000'+ selectedItem.number ).slice(-8)" size="xl" id="itemInfoModal" centered>
      <iframe
        :src="'/invoice/' + selectedItem.hash"
        width="100%"
        frameborder="0" >
      </iframe>

      <template #modal-footer>
        <button class="button" @click="hideModal()" v-if="!selectedItemEditable">Close</button>
      </template>
    </b-modal>

    <b-modal ref="deleteConfirmation" :title="'Invoice ' + ('00000000'+ selectedItem.number ).slice(-8)" size="md" id="itemInfoModal" centered>
      Are you sure you want to delete this invoice?

      <template #modal-footer>
        <button class="button" @click="hideConfirmation()">Cancel</button>
        <button class="button warning" @click="deleteInvoice(selectedItem.hash)">Delete</button>
      </template>
    </b-modal>

    <b-modal ref="payAllConfirmation" title="Mark current period as paid?" size="md" id="itemInfoModal" centered>
      Are you sure you want to mark all the invoices in the current period?

      <template #modal-footer>
        <button class="button" @click="hidePayAll()">Cancel</button>
        <button class="button success" @click="payAll">Mark as paid</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import List from '@/components/admin/List'
import Modal from '@/components/invoice'

export default {
  components: {
    List
  },
  data () {
    return {
      cloudinary_bucket: process.env.VUE_APP_CLOUDINARY_NAME,
      selectedItem: {},
      selectedItemOriginal: {},
      props: {
        plural: 'invoices',
        singular: 'invoice',
        fetchUrl: 'invoices/' + this.$route.fullPath.substring(this.$route.fullPath.lastIndexOf('/') + 1),
        actionUrl: 'invoice',
        canAdd: false,
        fields: [
          {
            key: 'status',
            sortable: true
          },
          {
            key: 'number',
            sortable: true
          },
          {
            key: (this.$route.fullPath.substring(this.$route.fullPath.lastIndexOf('/') + 1) == 'clients') ? 'to_name' : 'from_name',
            label: 'Name',
            sortable: true
          },
          {
            key: 'period_from',
            label: 'Month',
            sortable: true
          },
          {
            key: 'due_date',
            sortable: true
          },
          {
            key: 'total',
            sortable: true
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'actions'
          }
        ],
        modalComponent: Modal
      }
    }
  },
  methods: {
    showModal (editBool, item) {
      this.$refs['modal'].show()

      if(item) {
        this.selectedItemOriginal = JSON.parse(JSON.stringify(item))
        this.selectedItem = item
      } else {
        this.selectedItemOriginal = {}
        this.selectedItem = {}
        this.newItem = true
      }

      this.selectedItemEditable = editBool
    },

    hideModal () {
      this.$refs['modal'].hide()
      this.selectedItem = {}
      this.selectedItemOriginal = {}
      this.selectedItemEditable = false
      this.newItem = false
    },

    downloadItem (url, label) {
      this.$http.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    },

    regenerateInvoice (hash) {
      const self = this
      const loader = this.$loading.show()

      this.$http.get('/invoice/' + hash + '/regenerate')
      .then(function () {
        loader.hide()
        self.hideModal()
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    showConfirmation (item) {
      this.$refs['deleteConfirmation'].show()
      this.selectedItem = item
    },

    hideConfirmation () {
      this.$refs['deleteConfirmation'].hide()
      this.selectedItem = {}
    },

    showPayAll () {
      this.$refs['payAllConfirmation'].show()
    },

    hidePayAll () {
      this.$refs['payAllConfirmation'].hide()
    },

    deleteInvoice (hash) {
      // TODO: Use self.$refs.list.action() instead, so we don't duplicate actions.
      const self = this
      const loader = this.$loading.show()

      this.$http.delete('/invoice/' + hash)
      .then(function () {
        loader.hide()

        self.$bvToast.toast('Invoice deleted successfully.', {
          title: 'Woohoo!',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: true
        })

        self.$refs.list.fetchItems()

        self.hideConfirmation()
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    payAll () {
      const self = this
      const loader = this.$loading.show()

      this.$http.post('/invoices/pay/all')
      .then(function () {
        loader.hide()

        self.$bvToast.toast('Invoices updated successfully.', {
          title: 'Woohoo!',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: true
        })

        self.$refs.list.fetchItems()

        self.hidePayAll()
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    /*** TEMPLATES ***/
    status (status, due_date) {
      if(status == 1) {
        return 'paid'
      } else {
        const today = this.$dayjs().utc()

        if(this.$dayjs.utc(today).isSameOrBefore(due_date)) {
          return 'pending'
        } else {
          return 'overdue'
        }
      }
    },

    rowClass (item, type) {
      if (item && type === 'row') {
        return 'table-row'
      } else {
        return null
      }
    },
    /*** TEMPLATES ***/
  },
}
</script>

<style lang="scss">
.list {
  .status {
    border-radius: 5px !important;
    border: 0 !important;
    font-size: 12px !important;
    font-weight: bold !important;
    padding: 3px !important;
    width: 60px;
    display: block;
    text-align: center;

    &.paid {
      background-color: #1cab3d !important;
      color: white;
    }

    &.pending {
      background-color: #ffda55 !important;
      color: black;
    }

    &.overdue {
      background-color: #e10f0f !important;
      color: white;
    }
  }
}

#itemInfoModal {
  .modal-header {
    text-transform: capitalize;
  }

  .modal-xl {
    max-width: 90% !important;
  }

  iframe {
    height: 100vh;
  }
}
</style>
