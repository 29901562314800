<template>
  <b-container fluid>
    <h1>Profitability</h1>

    <b-row class="monthSelector">
      <b-col class="text-right">
        <button @click="previousMonth" class="navigationButton" v-if="enableGoPrev">
          <b-icon-caret-left-fill></b-icon-caret-left-fill>
        </button>
      </b-col>

      <b-col cols="3" class="text-center navDate">
        {{ $dayjs.utc(displayDate).format('YYYY') }}
      </b-col>

      <b-col class="text-left">
        <button @click="nextMonth" class="navigationButton" v-if="enableGoNext">
          <b-icon-caret-right-fill></b-icon-caret-right-fill>
        </button>
      </b-col>
    </b-row>

    <b-card no-body v-if="report">
      <b-tabs pills card vertical class="profitability-tabs">
        <b-tab v-for="(client, index) in report " :title="client.name" :key="index">
          <Bar
            class="charts"
            :chart-options="chartOptions"
            :chart-data="client.chartData"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

export default {
  components: {
    Bar
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            // suggestedMax: 200000
          },
          y: {
            stacked: true
          }
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            color: 'white',
            textAlign: 'center',
            font: {
              weight: 'bold'
            },
            /* eslint-disable */
            formatter: function(value, context) {
              if(value) {
                return '$ ' + parseFloat(value).toFixed(2)
              } else {
                return ''
              }
            }
            /* eslint-enable */
          }
        }
      },
      report: [],
      displayDate: this.$dayjs.utc().startOf('year'),
      minDate: this.$dayjs.utc(new Date(2022, 1, 1)),
      maxDate: this.$dayjs.utc().startOf('year').subtract(1, 'year')
    }
  },
  computed: {
    enableGoPrev: function () {
      if(this.$dayjs.utc(this.displayDate).isSameOrAfter(this.minDate, 'year')) {
        return true
      } else {
        return false
      }
    },
    enableGoNext: function () {
      if(this.$dayjs.utc(this.displayDate).isSameOrBefore(this.maxDate, 'year')) {
        return true
      } else {
        return false
      }
    },
  },
  created () {
    this.fetchReport()
  },
  mounted () {
    let charts = document.getElementsByClassName('charts')

    charts.forEach(chart => {
      let canvas = chart.getElementsByTagName('canvas')
      
      canvas.forEach(canvasItem => {
        canvasItem.removeAttribute('width')
        canvasItem.removeAttribute('height')
        canvasItem.setAttribute('style', 'display: block; width: 100% !important; height: auto !important;')
      })
    })
  },
  methods: {
    fetchReport () {
      const self = this
      const loader = this.$loading.show()

      this.$http.get('/profitability/all/' + this.displayDate.get('year'))
      .then(function (response) {
        loader.hide()
        self.report = response.data

        self.report.forEach(line => {
          line.chartData.datasets[0].backgroundColor = '#5477ab'
          line.chartData.datasets[1].backgroundColor = '#696969'
          line.chartData.datasets[2].backgroundColor = []

          for (let i = 0; i < 12; i++) {
            let percentile = line.chartData.datasets[2].percentile[i]
            let color = null

            if(percentile <= 0) {
              color = '#b31414'
            } else if(percentile > 0 && percentile <= 10) {
              color = '#cd5e19'
            } else if(percentile > 10 && percentile <= 20) {
              color = '#cdb619'
            } else if(percentile > 20 && percentile <= 40) {
              color = '#36a01a'
            } else if(percentile > 40) {
              color = '#629337'
            }

            line.chartData.datasets[2].backgroundColor[i] = color
          }
        })
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    previousMonth () {
      this.displayDate = this.$dayjs.utc(this.displayDate).subtract(1, 'year')
      this.fetchReport()
    },

    nextMonth () {
      this.displayDate = this.$dayjs.utc(this.displayDate).add(1, 'year')
      this.fetchReport()
    },
  }
}
</script>

<style lang="scss">
.profitability-tabs {
  .nav {
    font-size: 0.8em;
  }
}
</style>
