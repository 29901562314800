<template>
  <b-container fluid>
    <b-row class="vh-100 text-center" align-v="center">
      <b-col>
        <b-form class="login-form">
          <label class="sr-only" for="username">Email</label>
          <b-input-group>
            <b-form-input id="username" v-model="form.username" placeholder="Username" @keyup.enter="login()" />
          </b-input-group>

          <label class="sr-only" for="password">Password</label>
          <b-input-group>
            <b-form-input id="password" v-model="form.password" placeholder="Password" type="password" @keyup.enter="login()" />
          </b-input-group>

          <b-form-checkbox class="remember-me">
            Remember me
          </b-form-checkbox>

          <div class="text-right">
            <b-button variant="primary" @click="login()">
              Login
            </b-button>
          </div>

          <div class="separator"></div>

          <div id="signin_button"></div>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import jwt_decode from "jwt-decode"

export default {
  data () {
    return {
      error: null,
      form: {
        username: null,
        password: null
      },
      key: 0
    }
  },
  mounted () {
    if(document.getElementById('google-login-script')) {
      this.initializeGoogleLogin()
    } else {
      this.createGoogleLogin()
    }

    if (this.$localStorage.get('token')) {
      this.$router.push('/')
    }
  },
  methods: {
    createGoogleLogin () {
      let googleScript = document.createElement('script')
      googleScript.src = 'https://accounts.google.com/gsi/client'
      googleScript.id  = 'google-login-script'
      document.head.appendChild(googleScript)
      
      this.initializeGoogleLogin()
    },
    initializeGoogleLogin () {
      if(typeof window.google == 'undefined') {
        setTimeout(() => this.initializeGoogleLogin(), 500)
      } else {
        window.google.accounts.id.initialize({
          client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
          auto_select: true,
          callback: this.handleCredentialResponse
        })

        window.google.accounts.id.renderButton(
          document.getElementById('signin_button'),
          {
            type: 'standard',
            theme: 'filled_blue',
            size: 'large',
            shape: 'pill',
            width: 310
          }
        )
      }
    },
    login () {
      const self = this

      const loader = this.$loading.show();

      this.$http.post('/login', this.form)
      .then(function (response) {
        loader.hide()

        self.$localStorage.set('token', response.data.token)
        self.$localStorage.set('user', JSON.stringify(response.data.user))

        self.$root.logged = true
        self.$router.push('/')
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    handleCredentialResponse (googleResponse) {
      const googleUser = jwt_decode(googleResponse.credential)
      const self = this
      const loader = this.$loading.show()

      this.$http.post('/login/google', { username: googleUser.email, id_token: googleResponse.credential }).then(function (response) {
        loader.hide()

        self.$localStorage.set('token', response.data.token)
        self.$localStorage.set('user', JSON.stringify(response.data.user))

        self.$root.logged = true
        self.$router.push('/')
      })
    },

    onFailure (error) {
      console.log(error)
    }
  }
}
</script>

<style lang="scss">
.login-form {
  width: 100%;
  max-width: 350px !important;
  margin: auto;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  padding: 20px;
  text-align: left;

  #password {
    margin-top: 5px;
  }

  .remember-me {
    margin-top: 10px;
  }

  .separator {
    border-top: 1px solid #e6e6e6;
    margin-top: 20px;
    padding-top: 20px;
  }

  .abcRioButton {
    width: 100% !important;
  }
}
</style>
