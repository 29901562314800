<template>
  <b-container fluid class="crud-modal">
    <div v-if="item">
      <b-row>
        <b-col class="input-with-labels">
          <label for="name">Name:</label>
          <b-form-input v-model="item.name" id="name" :disabled="editable == false" :class="{ 'hasError': !$v.item.name.required }"></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="country">Source:</label>
          <b-form-select v-model="item.source" id="source" :disabled="editable == false" :class="{ 'hasError': !$v.item.source.required }">
            <b-form-select-option value="">-- Select a source --</b-form-select-option>
            <b-form-select-option v-for="source in sources" :value="source" v-bind:key="source">{{ source }}</b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="state">Campaign:</label>
          <b-form-select v-model="item.campaign" id="campaign" :disabled="editable == false" :class="{ 'hasError': !$v.item.campaign.required }">
            <b-form-select-option value="">-- Select a campaign --</b-form-select-option>
            <b-form-select-option v-for="campaign in campaigns" :value="campaign" v-bind:key="campaign">{{ campaign }}</b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col class="input-with-labels">
          <label for="city">City:</label>
          <b-form-input v-model="item.city" id="city" :disabled="editable == false" :class="{ 'hasError': !$v.item.city.required }"></b-form-input>
        </b-col>
      </b-row> -->
    </div>
  </b-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    itemProp: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      item: this.itemProp,
      sources: [
        'Email',
        'LinkedIn'
      ],
      campaigns: [
        'Staff Augmentation',
        'Swat Team'
      ],
      valid: false,
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      source: {
        required
      },
      campaign: {
        required
      }
    }
  },
  watch: {
    item: {
      handler () {
        this.$emit('ready', !this.$v.item.$invalid)
      },
      deep: true
    },
  },
  created () {
  },
  methods: {
  }
}
</script>
