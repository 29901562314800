<template>
  <b-container fluid>
    <h1>Time tracking</h1>

    <div v-if="projects.length">
      <b-row class="weekSelector">
        <b-col class="text-right">
          <button @click="previousWeek" class="navigationButton" v-if="enableGoPrev">
            <b-icon-caret-left-fill></b-icon-caret-left-fill>
          </button>
        </b-col>

        <b-col cols="3" class="text-center navDate">
          {{ $dayjs.utc(displayDate).weekday(0).format('MMMM') }} {{ $dayjs.utc(displayDate).weekday(0).format('DD') }} - {{ $dayjs.utc(displayDate).weekday(6).format('DD') }}
        </b-col>

        <b-col class="text-left">
          <button @click="nextWeek" class="navigationButton">
            <b-icon-caret-right-fill></b-icon-caret-right-fill>
          </button>
        </b-col>
      </b-row>

      <b-row class="dayLine">
        <b-col cols="2">&nbsp;</b-col>
        <b-col v-for="index in 7" :key="index" :class="index == 1 || index == 7 ? 'weekend' : 'weekday'">
          <div class="dayName">{{ $dayjs.utc(displayDate).weekday(index-1).format('ddd') }}</div>
          <div class="dayNumber">{{ $dayjs.utc(displayDate).weekday(index-1).format('MMM D') }}</div>
        </b-col>
      </b-row>

      <b-row v-for="project in projects" :key="project.id" class="projectLine">
        <b-col cols="2" class="projectName">{{ project.name }}</b-col>
        <b-col v-for="(timeslot, index) in project.TimeTracking" :key="index" :class="index == 0 || index == 6 ? 'weekend' : 'weekday'">
          <time-slot v-bind.sync="project.TimeTracking[index]" :key="timeslot.id" :saveWeek="saveWeek" />
        </b-col>
      </b-row>

      <div class="text-right">
        <button v-if="enableSave" class="button" @click="saveWeek">Save week</button>
        <button v-else class="button disabled">Save week</button>
      </div>
    </div>

    <div v-else>You have no projects assigned yet.</div>
  </b-container>
</template>

<script>
import Vue from 'vue'
import timeSlot from '@/components/time'

export default {
  components: {
    timeSlot
  },
  data () {
    return {
      projects: [],
      hours: 0,
      displayDate: this.$dayjs.utc().startOf('day').weekday(0),
      minDate: this.$dayjs.utc().startOf('month').subtract(1, 'month'),
      maxDate: this.$dayjs.utc().startOf('day').weekday(-7)
    }
  },
  computed: {
    enableGoPrev: function () {
      if(this.$dayjs.utc(this.displayDate).isSameOrAfter(this.minDate)) {
        return true
      } else {
        return false
      }
    },

    enableGoNext: function () {
      if(this.$dayjs.utc(this.displayDate).isSameOrBefore(this.maxDate)) {
        return true
      } else {
        return false
      }
    },

    enableSave: function() {
      let enableButton = false

      if(this.projects) {
        this.projects.forEach(project => {
          project.TimeTracking.forEach(timeSlot => {
            if(timeSlot.dirtyTime || timeSlot.dirtyDesc) {
              enableButton = true
            }

            if(timeSlot.invalid) {
              enableButton = false
            }
          });
        })
      }

      return enableButton
    }
  },
  created () {
    this.fetchProjects()
  },
  methods: {
    fetchProjects () {
      const self = this
      const loader = this.$loading.show();

      this.$http.get('/timetracking?date=' + this.$dayjs.utc(this.displayDate).format('DD-MM-YYYY'))
      .then(function (response) {
        loader.hide()

        if(response.data.length) {
          self.projects = response.data

          self.projects.forEach(project => {
            const timeSlots = []

            for(var i=0; i<7; i++) {
              const foundDate = project.TimeTracking.find(element => element.date == self.$dayjs.utc(self.displayDate).add(i, 'day').format('YYYY-MM-DD'));

              if(foundDate) {
                Vue.set(foundDate, 'dirtyTime', false)
                Vue.set(foundDate, 'dirtyDesc', false)
                Vue.set(foundDate, 'invalid', false)
                timeSlots.push(foundDate)
              } else {
                timeSlots.push({
                  blocked: false,
                  date: self.$dayjs.utc(self.displayDate).add(i, 'day').format('YYYY-MM-DD'),
                  description: null,
                  time: null,
                  projectId: project.id,
                  dirtyTime: false,
                  dirtyDesc: false,
                  invalid: false,
                })
              }
            }

            Vue.set(project, 'TimeTracking', timeSlots)
          });
        }
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    previousWeek () {
      this.displayDate = this.$dayjs.utc(this.displayDate).subtract(7, 'day')
      this.fetchProjects()
    },

    nextWeek () {
      this.displayDate = this.$dayjs.utc(this.displayDate).add(7, 'day')
      this.fetchProjects()
    },

    saveWeek () {
      if(this.enableSave) {
        const loader = this.$loading.show()
        let requests = 0;

        this.projects.forEach((project) => {
          project.TimeTracking.forEach((timeSlot) => {
            var selfTimeSlot = timeSlot

            if(timeSlot.id || timeSlot.time) {
              if(timeSlot.dirtyTime || timeSlot.dirtyDesc) {
                requests++

                this.$http.post('/timetracking', timeSlot)
                .then(function(response) {
                  requests--

                  if(!selfTimeSlot.id) {
                    Vue.set(selfTimeSlot, 'id', response.data.id)
                    Vue.set(selfTimeSlot, 'userId', response.data.userId)
                  }

                  Vue.set(selfTimeSlot, 'dirtyTime', false)
                  Vue.set(selfTimeSlot, 'dirtyDesc', false)

                  if(requests == 0) {
                    loader.hide()
                  }
                })
                .catch(function(error) {
                  requests--

                  if(requests == 0) {
                    loader.hide()
                  }

                  console.log(error)
                })
              } else {
                if(requests == 0) {
                  setTimeout(() => loader.hide(), 500)
                }
              }
            }
          })
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.weekSelector {
  padding-bottom: 15px;

  .navigationButton {
    background-color: transparent;
    border: 0 !important;
    font-size: 1.5em !important;

    &:hover {
      color: #e10f0f;
    }
  }

  .navDate {
    padding-top: 4px;
    font-weight: bold;
    font-size: 1.3em;
  }
}

.dayLine {
  .col:not(:last-child) {
    border-right: 1px solid #cacaca;
  }

  .dayName {
    font-weight: bold;
    font-size: 1.3em;
  }

  .dayNumber {
    font-size: 0.9em;
  }

  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
}

.projectLine {
  .col:not(:last-child) {
    border-right: 1px solid #cacaca;
  }

  border-bottom: 1px solid #cacaca;

  .projectName {
    padding-top: 20px;
  }
}

.dayLine .col, .projectLine .col {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;

  &.weekend {
    background-color: #e5e5e5;
  }
}

.button {
  margin-top: 20px;
}
</style>
