<template>
  <div>
    <list v-bind="props">
      <template #cell(link)="row">
        <b-input-group>
          <b-form-input
            :ref="'link' + row.item.id"
            readonly
            :value="frontendUrl + 'campaign/' + row.item.hash"
            @focus="$event.target.select()"
          />
          <b-input-group-append>
            <b-button variant="outline-success"><b-icon-clipboard @click="copyLink('link' + row.item.id)" /></b-button>
          </b-input-group-append>
        </b-input-group>
      </template>

      <template #cell(lastView)="row">
        <div class="text-right">{{ $dayjs.utc(row.item.lastView).format('DD-MMM-YY') }}</div>
      </template>
    </list>
  </div>
</template>

<script>
import List from '@/components/admin/List'
import Modal from '@/views/admin/networking/prospectsModal'

export default {
  components: {
    List
  },
  
  data () {
    return {
      frontendUrl: process.env.VUE_APP_FRONTEND_URL,
      props: {
        plural: 'prospects',
        singular: 'prospect',
        fetchUrl: 'prospects',
        actionUrl: 'prospect',
        fields: [
          {
            key: 'id',
            class: 'idColumn'
          },
          {
            key: 'name'
          },
          {
            key: 'source'
          },
          {
            key: 'campaign'
          },
          {
            key: 'lastView'
          },
          {
            key: 'link'
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'actions'
          }
        ],
        modalComponent: Modal
      }
    }
  },
  methods: {
    async copyLink (inputRef) {
      const self = this
      const link = this.$refs[inputRef].value

      try {
        await navigator.clipboard.writeText(link);
        self.$bvToast.toast('Link copied to clipboard.', {
          title: 'Woohoo!',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: true
        })
      } catch (err) {
        self.$bvToast.toast(err, {
          title: 'Wait... what?',
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true
        })
      }
    }
  }
}
</script>
