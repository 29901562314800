<template>
  <div v-if="invoice && invoice.number && invoice.user_id && invoice.client_id">
    <b-container fluid class="invoice">
      <b-row class="section invoice-header">
        <b-col>
          <div><label>Nombre:</label> {{ invoice.from_name }}</div>
          <div v-if="invoice.from_address"><label>Direcci&oacute;n:</label> {{ invoice.from_address }}</div>
          <div v-if="invoice.from_zipcode"><label>C&oacute;digo postal:</label> {{ invoice.from_zipcode }}</div>
          <div v-if="invoice.from_tax_id"><label>Id Impositivo:</label> {{ invoice.from_tax_id }}</div>
        </b-col>

        <b-col class="invoice-data">
          <div class="invoice-number">{{ ('00000000'+ invoice.number ).slice(-8) }}</div>
          <div class="invoice-date"><label>Fecha de emisi&oacute;n:</label> {{ $dayjs.utc(invoice.date).format('DD-MM-YYYY') }}</div>
        </b-col>
      </b-row>

      <b-row class="section invoice-period">
        <b-col>
          <div><label>Per&iacute;odo Desde:</label> {{ $dayjs.utc(invoice.period_from).format('DD-MM-YYYY') }}</div>
        </b-col>

        <b-col>
          <div><label>Hasta:</label> {{ $dayjs.utc(invoice.period_to).format('DD-MM-YYYY') }}</div>
        </b-col>

        <b-col>
          <div><label>Vencimiento:</label> {{ $dayjs.utc(invoice.due_date).format('DD-MM-YYYY') }}</div>
        </b-col>
      </b-row>

      <b-row class="section invoice-recipient">
        <b-col>
          <div><label>Nombre:</label> {{ invoice.to_name }}</div>
          <div><label>RUT:</label> {{ invoice.to_tax_id }}</div>
        </b-col>

        <b-col>
          <div><label>Direcci&oacute;n:</label> {{ invoice.to_address }}</div>
          <div><label>C&oacute;digo postal:</label> {{ invoice.to_zipcode }}</div>
        </b-col>
      </b-row>

      <b-row class="section items">
        <b-col>
          <b-row class="header">
            <b-col>
              Descripci&oacute;n
            </b-col>

            <b-col cols="2" class="currency">
              Importe
            </b-col>
          </b-row>

          <b-row v-for="(item, index) in invoice.items" :key="index">
            <b-col v-if="item.price > 0 || item.canDelete">
              <div v-if="item.canDelete" class="remove-item-container">
                <span class="remove-item"><b-icon-x-circle-fill font-scale="1.5" style="color: #f00000;" @click="removeItem(index)" /></span>
                <b-form-select v-model="item.description" :options="extraOptions"></b-form-select>
              </div>
              <div v-else>{{ item.description }}</div>
            </b-col>

            <b-col cols="2" class="currency" v-if="item.price > 0 || item.canDelete">
              <div v-if="item.canDelete">
                <b-form-input v-model="item.price" @keypress="checkPrice" />
              </div>
              <div v-else>USD {{ item.price }}</div>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="add-new-item">
              <b-icon-plus-circle-fill font-scale="2" style="color: #339022;" @click="addNewItem" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="section">
        <b-col cols="8">
          <div><label>Notas:</label></div>
          <div>
            <b-form-textarea
              v-model="invoice.notes"
              placeholder="Notas..."
              rows="5"
            ></b-form-textarea>
          </div>

          <div class="notes"><label>Pagar en:</label> <b-form-select v-model="invoice.payment_account" :options="paymentOptions"></b-form-select></div>
        </b-col>

        <b-col class="currency">
          <label>Total:</label>
        </b-col>

        <b-col class="currency">
          USD {{ total }}
        </b-col>
      </b-row>
    </b-container>

    <div class="text-right">
      <button class="button" @click="saveInvoice()" :disabled="!invoice.payment_account">Generate invoice</button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data () {
    return {
      user: null,
      invoice: {
        number: null,
        user_id: null,
        client_id: null,
        from_name: null,
        from_address: null,
        from_zipcode: null,
        from_tax_id: null,
        to_name: null,
        to_address: null,
        to_zipcode: null,
        to_tax_id: null,
        date: new Date(),
        due_date: null,
        period_from: null,
        period_to: null,
        items: [],
        notes: null,
        payment_account: null
      },
      extraOptions: [
        { value: null, text: 'Please select an option' },
        { value: 'equipment', text: 'Pago de equipo' },
        { value: 'referral', text: 'Bono por referido' },
      ],
      paymentOptions: [
        { value: null, text: 'Pagar en' },
        { value: 'payoneer', text: 'Payoneer' },
        { value: 'transferwise', text: 'TransferWise' },
        { value: 'bankaccount', text: 'Cuenta bancaria' }
      ]
    }
  },
  computed: {
    total: function () {
      let total = 0

      this.invoice.items.forEach(item => {
        if(item.description) {
          if(item.description == 'equipment') {
            total -= parseFloat(item.price)
          } else {
            total += parseFloat(item.price)
          }
        }
      })

      return total.toFixed(2)
    }
  },
  created () {
    this.fetchUser()
    this.fetchCompany()
  },
  methods: {
    fetchUser () {
      const self = this
      const loader = this.$loading.show();

      this.$http.get('/user')
      .then(function (response) {
        const user = response.data
        self.user = user
        loader.hide()

        let address = user.address1
        address += (user.address2) ? ', ' + user.address2 : ''
        address += '. ' + user.city
        address += ', ' + user.state.name_es
        address += user.country.name_es ? '. ' + user.country.name_es : '. ' + user.country.name_en
        address += '.'

        Vue.set(self.invoice, 'user_id', user.id)
        Vue.set(self.invoice, 'from_name', user.firstname + ' ' + user.lastname)
        Vue.set(self.invoice, 'from_address', address)
        Vue.set(self.invoice, 'from_zipcode', user.zipcode)
        Vue.set(self.invoice, 'from_tax_id', user.tax_id)

        self.fetchCurrentInvoice()
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    fetchCompany () {
      const self = this
      const loader = this.$loading.show();

      this.$http.get('/company')
      .then(function (response) {
        const company = response.data
        loader.hide()

        let address = company.address1
        address += (company.address2) ? ', ' + company.address2 : ''
        address += '. ' + company.city
        address += ', ' + company.state.name_es
        address += company.country.name_es ? '. ' + company.country.name_es : '. ' + company.country.name_en
        address += '.'

        Vue.set(self.invoice, 'client_id', company.id)
        Vue.set(self.invoice, 'to_name', company.name)
        Vue.set(self.invoice, 'to_address', address)
        Vue.set(self.invoice, 'to_zipcode', company.zipcode)
        Vue.set(self.invoice, 'to_tax_id', company.tax_id)
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    fetchCurrentInvoice () {
      const self = this
      const loader = this.$loading.show();

      this.$http.get('/self/invoice/current')
      .then(function (response) {
        loader.hide()

        let due_date = new Date(response.data.period_to)
        let items = []

        due_date.setDate(due_date.getDate() + 15)

        response.data.projects.forEach(item => {
          let description = 'Servicios de '
          description += self.user.title.service_es
          description += (item.client.id != 1) ? ' prestados al cliente ' + item.client.name : ''
          description += ' ('
          description += (item.client.state.country.name_es) ? item.client.state.country.name_es : item.client.state.country.name_en
          description += ')'
          description += '. Proyecto: ' + item.name

          items.push({
            canDelete: false,
            description: description,
            price: parseFloat(item.rate * item.hours).toFixed(2),
            clientId: item.clientId,
            projectId: item.id
          })
        })

        Vue.set(self.invoice, 'number', response.data.number)
        Vue.set(self.invoice, 'items', items)
        Vue.set(self.invoice, 'period_from', response.data.period_from)
        Vue.set(self.invoice, 'period_to', response.data.period_to)
        Vue.set(self.invoice, 'due_date', due_date)
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },

    addNewItem() {
      this.invoice.items.push({
        canDelete: true,
        description: null,
        price: null
      })
    },

    removeItem(index) {
      this.invoice.items.splice(index, 1)
    },

    checkPrice() {
      if(!/^[0-9.]*$/.test(event.key)) {
        event.preventDefault()
      }
    },

    saveInvoice() {
      const self = this
      const loader = this.$loading.show()

      this.invoice.total = this.total

      this.$http.post('/invoice', this.invoice)
      .then(function(response) {
        loader.hide()
        self.invoice = []
        self.$emit('newInvoice', response.data)
      })
      .catch(function(error) {
        loader.hide()
        console.log(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice {
  border: double #000000;
  margin-bottom: 20px;

  label {
    font-weight: bold;
    margin: 0 !important;
  }

  .section {
    border-bottom: 1px solid #bcbcbc;
    padding: 10px;
  }

  .items {
    min-height: 300px;

    .header {
      font-weight: bold;
      font-size: 0.9em;
    }

    .service_name {
      text-transform: lowercase;
    }

    .add-new-item {
      text-align: center;
      margin: 15px 0;

      .b-icon {
        cursor: pointer;
      }
    }

    .remove-item-container {
      display: flex;

      .remove-item {
        padding-top: 5px;
        padding-right: 10px;
        cursor: pointer;
      }
    }
  }

  .invoice-header {
    .invoice-data {
      border-left: 2px solid #bcbcbc;
      text-align: right;
    }

    .invoice-number {
      font-size: 1.4em;
      font-weight: bold;
    }
  }

  .currency {
    text-align: right;
  }

  .notes {
    display: flex;
    padding-top: 10px;

    label {
      display: inline-flex !important;
      width: 100px;
      padding-top: 6px;
    }
  }
}
</style>
