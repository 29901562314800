<template>
  <div>
    <list v-bind="props"/>
  </div>
</template>

<script>
import List from '@/components/admin/List'
import Modal from '@/views/admin/networking/candidatesModal'

export default {
  components: {
    List
  },
  data () {
    return {
      props: {
        plural: 'candidates',
        singular: 'candidate',
        fetchUrl: 'clients',
        actionUrl: 'client',
        fields: [
          {
            key: 'id',
            class: 'idColumn'
          },
          {
            key: 'name'
          },
          {
            key: 'email'
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'actions'
          }
        ],
        modalComponent: Modal
      }
    }
  }
}
</script>
