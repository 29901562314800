<template>
  <div>
    <b-form-input v-model="formattedTime" class="timeSlot" v-bind:class="{ invalid : invalidTime, dirty : dirtyTime }" :disabled="blocked" @keydown.meta.enter.exact="saveWeek" @keyup.ctrl.enter.exact="saveWeek"></b-form-input>
    <b-form-textarea v-model="formattedDesc" class="descSlot" v-bind:class="{ invalid : invalidDesc, dirty : dirtyDesc }" :disabled="blocked" @keydown.meta.enter.exact="saveWeek" @keyup.ctrl.enter.exact="saveWeek" rows="5"></b-form-textarea>
  </div>
</template>

<script>
export default {
  props: {
    time: String,
    description: String,
    blocked: Boolean,
    dirtyTime: Boolean,
    dirtyDesc: Boolean,
    saveWeek: Function,
    invalid: Boolean
  },
  data () {
    return {
      formattedTime: this.time,
      formattedDesc: this.description,
      innerDirtyTime: this.dirtyTime,
      innerDirtyDesc: this.dirtyDesc,
      innerInvalid: this.invalid,
      invalidTime: false,
      invalidDesc: false
    }
  },
  methods: {
    updateTimeSlot () {
      this.$emit('update:time', this.formattedTime)
      this.$emit('update:description', this.formattedDesc)
      this.$emit('update:dirtyTime', this.innerDirtyTime)
      this.$emit('update:dirtyDesc', this.innerDirtyDesc)
    },
  },
  watch: {
    invalidTime () {
      if(this.invalidTime || this.invalidDesc) {
        this.innerInvalid = true
      } else {
        this.innerInvalid = false
      }

      this.$emit('update:invalid', this.innerInvalid)
    },
    formattedDesc: function() {
      this.innerDirtyDesc = true
      this.updateTimeSlot()
    },
    formattedTime: function(value, oldValue) {
      this.innerDirtyTime = true

      try {
        this.invalidTime = false

        if(value) {
          if(/^[0-9:]+$/.test(value)) {
            if(/^[\d]{1,2}[:][\d]{2}?$/.test(value)) { // 12:48
              const tmpTime = value.split(':')

              if(tmpTime[0] >= 24 || tmpTime[1] >= 60) {
                throw 'error';
              } else {
                this.updateTimeSlot()
              }
            } else if(/^[\d]{1,2}[:][\d]{1}?$/.test(value)) { // 12:5 ** INCOMPLETE NUMBER **
              const tmpTime = value.split(':')

              if(tmpTime[0] >= 24 || tmpTime[1] > 5) {
                throw 'error';
              } else {
                this.invalidTime = true
              }
            } else if(/^[\d]{1,2}[:]$/.test(value)) { // 12: ** INCOMPLETE NUMBER **
              const tmpTime = value.split(':')

              if(tmpTime[0] >= 24) {
                throw 'error';
              } else {
                this.invalidTime = true
              }
            } else if(/^[\d]{1,2}$/.test(value)) { // 12
              if(value > 24) {
                throw 'error';
              } else {
                this.updateTimeSlot()
              }
            } else {
              this.invalidTime = true
              throw 'error';
            }
          } else {
            this.invalidTime = true
            throw 'error';
          }
        } else {
          this.updateTimeSlot()
        }
      } catch (error) {
        this.$nextTick(() => {
          this.formattedTime = oldValue
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dirty {
  border: 1px solid #ffb100 !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 177, 0, 0.5);
  }
}

.invalid {
  border: 1px solid red !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 1, 0, 0.5) !important;
  }
}

.timeSlot {
  text-align: center;
}

.descSlot {
  margin-top: 10px;
}
</style>
