import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLocalStorage from 'vue-localstorage'
import Axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import VCalendar from 'v-calendar'
import dayjs from './plugins/dayjs.js'
import Vuelidate from 'vuelidate'

Vue.use(VueLocalStorage)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSidebarMenu)
Vue.use(Loading)
Vue.use(VCalendar)
Vue.use(dayjs)
Vue.use(Vuelidate)

/** AXIOS **/
const axios = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: { 'Cache-Control': 'no-cache' }
})

axios.interceptors.request.use(function (config) {
  config.headers.common['Authorization'] = 'Bearer ' + Vue.localStorage.get('token')
  return config
})

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  app.$bvToast.toast(error.response.data.error, {
    title: 'Error ' + error.response.status,
    variant: 'danger',
    autoHideDelay: 5000,
    appendToast: true
  })

  switch (error.response.status) {
    case 401:
      app.logout()
    break

    case 403:
      app.$router.push('/')
    break
  }
  return Promise.reject(error);
})

Vue.prototype.$http = axios
/** AXIOS **/

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (Vue.localStorage.get('token')) {
      next()
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    next()
  }
})

const app = new Vue({
  data: {
    logged: !!Vue.localStorage.get('token'),
    logout: function () {
      Vue.localStorage.remove('token')
      Vue.localStorage.remove('user')

      if(window.google) {
        window.google.accounts.id.disableAutoSelect()
      }

      app.$root.logged = false
      app.$router.push('/login')
    },
    countries: [],
    states: [],
    titles: [],
    getCountries: function () {
      this.$http.get('/countries')
      .then(function (response) {
        app.$root.countries = response.data
      })
    },
    getStates: function () {
      this.$http.get('/states')
      .then(function (response) {
        app.$root.states = response.data
      })
    },
    getTitles: function () {
      this.$http.get('/titles')
      .then(function (response) {
        app.$root.titles = response.data
      })
    },
  },
  router,
  render: h => h(App)
}).$mount('#app')
