<template>
  <div>
    <list v-bind="props" />
  </div>
</template>

<script>
import List from '@/components/admin/List'
import Modal from '@/views/admin/users/modal'

export default {
  components: {
    List
  },
  data () {
    return {
      props: {
        plural: 'users',
        singular: 'user',
        fetchUrl: 'users',
        actionUrl: 'user',
        fields: [
          {
            key: 'id',
            class: 'idColumn'
          },
          {
            key: 'firstname',
          },
          {
            key: 'lastname',
          },
          {
            key: 'actions',
            class: 'actions'
          }
        ],
        modalComponent: Modal
      }
    }
  }
}
</script>
