import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginLayout from '@/layouts/Login'
import Login from '@/views/Login'

import AppLayout from '@/layouts/App'
import Dashboard from '@/views/Dashboard'
import TimeTracking from '@/views/TimeTracking'
import Invoices from '@/views/Invoices'
import Profile from '@/views/Profile'
import ReleaseLog from '@/views/ReleaseLog'
import Invoice from '@/views/Invoice'

import AdminUsers from '@/views/admin/users/list'
import AdminClients from '@/views/admin/clients/list'
import AdminProjects from '@/views/admin/projects/list'
import AdminInvoices from '@/views/admin/invoices/list'
import AdminProspects from '@/views/admin/networking/prospects'
import AdminCandidates from '@/views/admin/networking/candidates'

import ReportsTimeTracking from '@/views/reports/TimeTracking'
import ReportsProfitability from '@/views/reports/Profitability'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: LoginLayout,
    children: [
      {
        path: '',
        component: Login,
      },
    ],
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '/',
        component: Dashboard,
        meta: { requiresAuth: true }
      },
      {
        path: '/time-tracking',
        component: TimeTracking,
        meta: { requiresAuth: true }
      },
      {
        path: '/invoices',
        component: Invoices,
        meta: { requiresAuth: true }
      },
      {
        path: '/profile',
        component: Profile,
        meta: { requiresAuth: true }
      },
      {
        path: '/release-log',
        component: ReleaseLog,
        meta: { requiresAuth: true }
      },
      {
        path: '/reports/time-tracking',
        component: ReportsTimeTracking,
        meta: { requiresAuth: true }
      },
      {
        path: '/reports/profitability',
        component: ReportsProfitability,
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/invoices/clients',
        component: AdminInvoices,
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/invoices/contractors',
        component: AdminInvoices,
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/networking/prospects',
        component: AdminProspects,
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/networking/candidates',
        component: AdminCandidates,
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/users',
        component: AdminUsers,
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/clients',
        component: AdminClients,
        meta: { requiresAuth: true }
      },
      {
        path: '/admin/projects',
        component: AdminProjects,
        meta: { requiresAuth: true }
      },
    ],
  },
  {
    path: '/invoice/:hash',
    component: Invoice
  },
  {
    // Not found route
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
