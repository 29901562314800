<template>
  <b-container fluid class="crud-modal">
    <div v-if="item">
      <b-row>
        <b-col class="input-with-labels">
          <label for="name">Name:</label>
          <b-form-input v-model="item.name" id="name" :disabled="editable == false" :class="{ 'hasError': !$v.item.name.required }"></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="client">Client:</label>
          <b-form-select v-model="item.clientId" id="client" :disabled="editable == false" :class="{ 'hasError': !$v.item.clientId.required }" class="mb-1">
            <b-form-select-option value="">-- Select a client --</b-form-select-option>
            <b-form-select-option v-for="client in clients" :value="client.id" v-bind:key="client.id">{{ client.name }}</b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="startDate">Start:</label>
          <b-form-datepicker id="startDate" v-model="item.startDate" :min="minimumStartDate" :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }" class="mb-1"></b-form-datepicker>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="endDate">End:</label>
          <b-form-datepicker id="endDate" v-model="item.endDate" :min="minimumEndDate" :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }" class="mb-1"></b-form-datepicker>
        </b-col>
      </b-row>

      <b-row v-if="users.length">
        <b-col class="input-with-labels">
          <label>Users:</label>
          <b-form-group class="form-control project-checkboxes">
            <b-container fluid>
              <b-row class="header">
                <b-col>
                  User
                </b-col>

                <b-col cols="2">
                  Rate to Client
                </b-col>

                <b-col cols="2">
                  Rate to User
                </b-col>

                <b-col cols="2">
                  Fixed Rate
                </b-col>
              </b-row>

              <b-row v-for="(user, index) in users" v-bind:key="index">
                <b-col>
                  {{ user.firstname }} {{ user.lastname }}
                </b-col>

                <b-col cols="2">
                  <b-form-input placeholder="Rate To Client" v-model="user.rateToClient" :id="'rateToClient' + index" :disabled="editable == false" :class="{ 'hasError': !$v.users.$each[index].rateToClient.decimal }"></b-form-input>
                </b-col>

                <b-col cols="2">
                  <b-form-input placeholder="Rate To User" v-model="user.rateToUser" :id="'rateToUser' + index" :disabled="editable == false" :class="{ 'hasError': !$v.users.$each[index].rateToUser.decimal }"></b-form-input>
                </b-col>

                <b-col cols="2">
                  <b-form-checkbox
                    v-model="user.fixed_rate"
                    value="true"
                    unchecked-value="false"
                    switch
                    size="lg"
                  >
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-container>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators'

export default {
  props: {
    itemProp: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      item: this.itemProp,
      filteredStates: [],
      valid: false,
      clients: [],
      minimumStartDate: this.$dayjs.utc().subtract(1, 'month').format('YYYY-MM-DD'),
      minimumEndDate: this.$dayjs.utc().add(1, 'month').format('YYYY-MM-DD'),
      users: []
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      clientId: {
        required
      },
    },
    users: {
      $each: {
        rateToClient: {
          decimal
        },
        rateToUser: {
          decimal
        }
      }
    }
  },
  watch: {
    item: {
      handler () {
        this.$emit('ready', !this.$v.item.$invalid)
      },
      deep: true
    },

    selectedProjects () {
      this.$emit('ready', !this.$v.item.$invalid)
    }
  },
  created () {
    this.fetchClients()
    this.fetchUsers()
  },
  methods: {
    fetchClients () {
      const self = this

      this.$http.get('/clients')
      .then(function (response) {
        self.clients = response.data
      })
    },

    fetchUsers () {
      const self = this

      this.$http.get('/users/project/'+this.item.id)
      .then(function (response) {
        self.users = response.data
      })
    },
  }
}
</script>

<style lang="scss">
.input-with-labels {
  label {
    &.form-control {
      width: auto;
      padding-top: auto;
      padding-right: auto;
      font-weight: normal;
      text-align: left;
    }
  }
}
</style>
