<template>
  <div id="app" :class="[{'collapsed' : collapsed}, {'onmobile' : isOnMobile}]">
    <sidebar-menu :menu="menu" @toggle-collapse="onToggleCollapse" @item-click="onItemClick" ref="sidebar">
      <div slot="header" class="logo">
        <img v-if="collapsed" src="@/assets/svg/isotype.svg" @click="goHome" />
        <img v-else src="@/assets/svg/logo.svg" @click="goHome" />
      </div>
      <div slot="footer" id="app-version">
        <router-link to="/release-log">v{{ version }}</router-link>
      </div>
      <span slot="toggle-icon"><b-icon-three-dots /></span>
      <span slot="dropdown-icon"><b-icon-caret-right-fill /></span>
    </sidebar-menu>

    <router-view :key="$route.fullPath" id="content" />
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data () {
    let role = JSON.parse(this.$localStorage.get('user')).role

    return {
      version: require('../../package.json').version,
      collapsed: false,
      isOnMobile: false,
      menu: [
        {
          href: '/',
          title: 'Dashboard',
          icon: {
            element: 'b-icon-grid1x2',
          }
        },
        {
          href: '/time-tracking',
          title: 'Time Tracking',
          icon: {
            element: 'b-icon-clock',
          }
        },
        {
          href: '/invoices',
          title: 'Invoices',
          icon: {
            element: 'b-icon-receipt',
          },
          hidden: role == 1 ? true : false
        },
        /*** CRUDS ***/
        {
          header: true,
          title: 'Admin',
          hiddenOnCollapse: true,
          hidden: role > 10 ? true : false
        },
        {
          href: '/admin/users',
          title: 'Users',
          icon: {
            element: 'b-icon-people',
          },
          hidden: role > 10 ? true : false
        },
        {
          href: '/admin/clients',
          title: 'Clients',
          icon: {
            element: 'b-icon-briefcase',
          },
          hidden: role > 10 ? true : false
        },
        {
          href: '/admin/projects',
          title: 'Projects',
          icon: {
            element: 'b-icon-tools',
          },
          hidden: role > 10 ? true : false
        },
        /*** CRUDS ***/

        /*** INVOICES ***/
        {
          title: 'Invoices',
          icon: {
            element: 'b-icon-receipt',
          },
          child: [
            {
              href: '/admin/invoices/clients',
              title: 'Clients',
              icon: {
                element: 'b-icon-bullseye',
              }
            },
            {
              href: '/admin/invoices/contractors',
              title: 'Contractors',
              icon: {
                element: 'b-icon-laptop',
              }
            }
          ],
          hidden: role > 10 ? true : false
        },
        /*** INVOICES ***/

        /*** NETWORKING ***/
        {
          title: 'Networking',
          icon: {
            element: 'b-icon-hdd-network',
          },
          child: [
            {
              href: '/admin/networking/prospects',
              title: 'Prospects',
              icon: {
                element: 'b-icon-graph-up',
              }
            },
            // {
            //   href: '/admin/networking/candidates',
            //   title: 'Candidates',
            //   icon: {
            //     element: 'b-icon-person-check-fill',
            //   }
            // }
          ],
          hidden: role > 10 ? true : false
        },
        /*** NETWORKING ***/

        /*** REPORTS ***/
        {
          title: 'Reports',
          icon: {
            element: 'b-icon-file-bar-graph',
          },
          child: [
            {
              href: '/reports/time-tracking',
              title: 'Time Tracking',
              icon: {
                element: 'b-icon-clock',
              },
            },
            {
              href: '/reports/profitability',
              title: 'Profitability',
              icon: {
                element: 'b-icon-cash-stack',
              },
            },
          ],
          hidden: role > 10 ? true : false
        },
        /*** REPORTS ***/

        {
          href: '/profile',
          title: 'Profile',
          icon: {
            element: 'b-icon-person-circle',
          }
        },
        {
          title: 'Logout',
          icon: {
            element: 'b-icon-door-open',
          }
        }
      ]
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    this.$refs.sidebar.$children[3].itemShow = true

    if(this.$root.logged && JSON.parse(Vue.localStorage.get('user')).role <= 10) {
      if(!this.$root.countries.length) {
        this.$root.getCountries()
      }

      if(!this.$root.states.length) {
        this.$root.getStates()
      }

      if(!this.$root.titles.length) {
        this.$root.getTitles()
      }
    }
  },
  methods: {
    onToggleCollapse () {
      this.collapsed = !this.collapsed
    },
    /* eslint-disable no-unused-vars */
    onItemClick(event, item, node) {
      if(item.title == 'Logout') {
        this.$root.logout()
      }
    },
    /* eslint-enable no-unused-vars */
    goHome () {
      this.$router.push('/')
    },
    onResize () {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
      } else {
        this.isOnMobile = false
      }
    }
  }
}
</script>

<style lang="scss">
.modal-content {
  input:not([type='checkbox']), .custom-select {
    margin: 2px 0px;
  }
}

.title {
  font-size: 1.1em;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.strike-behind {
  &:before, &:after{
    content: "";
    border-bottom: 1px solid #cecece;
    margin: auto;
  }

  &:before {
    width: 10px;
    margin-right: 10px
  }

  &:after {
    flex: 1 1;
    margin-left: 10px;
  }
}

#app {
  padding-left: 350px;
  transition: 0.3s ease;

  &.collapsed {
    padding-left: 50px;
  }

  &.onmobile {
    padding-left: 50px;
  }

  .v-sidebar-menu {
    .logo {
      margin: 10px;

      img {
        width: 100%;
        cursor: pointer;
      }
    }

    .vsm--mobile-bg {
      background-color: rgba(30, 30, 30, 1) !important;
    }

    .vsm--header {
      display: flex;
      flex-direction: row;

      &:before, &:after{
        content: "";
        border-bottom: 1px solid #ffffff1f;
        margin: auto;
      }

      &:before {
        width: 10px;
        margin-right: 10px
      }

      &:after {
        flex: 1 1;
        margin-left: 10px;
      }
    }

    .vsm--icon {
      padding: 6px;
      width: 2em;
      height: 2em;
    }

    .vsm--link {
      color: #e5e5e5;
    }

    .vsm--link_active {
      box-shadow: 3px 0px 0px 0px #d6c279 inset;

      .vsm--icon, .vsm--title {
        color: #d6c279;
      }
    }

    .vsm--icon {
      background-color: transparent !important;
    }

    .vsm--item_open {
      .vsm--link_level-1 {
        background-color: rgba(30, 30, 30, 0.5) !important;
      }
    }

    .vsm--item {
      .vsm--list {
        padding: 0 !important;
        .vsm--icon {
          margin-left: 20px;
        }
      }
    }

    #app-version {
      font-size: 0.8em;
      font-weight: bold;
      margin: 5px;

      a {
        color: #e5e5e5;
      }
    }
  }

  #content {
    padding: 20px;

    h1 {
      text-transform: uppercase;
    }

    .monthSelector {
      padding-bottom: 15px;

      .navigationButton {
        background-color: transparent;
        border: 0 !important;
        font-size: 1.5em !important;

        &:hover {
          color: #e10f0f;
        }
      }

      .navDate {
        padding-top: 4px;
        font-weight: bold;
        font-size: 1.3em;
      }
    }
  }
}

.button {
  transition: 0.5s;
  border-radius: 30px;
  background-color: #4285f4 !important;
  color: white !important;
  border: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: bold;
  padding: 8px 30px;
  background: linear-gradient(transparent,rgba(0, 0, 0, 0.3)) top/100% 800%;

  &.warning {
    background-color: #d64343 !important;
  }

  &.success {
    background-color: #63a548 !important;
  }

  &:hover {
    background-position:bottom;
  }

  &:disabled,
  &[disabled],
  &.disabled {
    background-color: #c8c8c8 !important;
    color: #a5a5a5;
    cursor: not-allowed;
  }
}

textarea {
  resize: none !important;
}

.input-with-labels {
  display: inline-flex;

  label {
    width: 120px;
    padding-top: 7px;
    padding-right: 5px;
    font-weight: bold;
    text-align: right;
  }

  .form-control {
    height: auto;

    .custom-control-label {
      width: auto;
      padding-top: 0;
      padding-right: 0;
      font-weight: normal;
      text-align: left;

      span {
        font-size: 0.8em;
      }
    }
  }

  input:not([type='checkbox']), select {
    &.hasError {
      border-color: #e10f0f;

      &:focus {
        box-shadow: 0 0 0 0.2rem #e10f0f50 !important;
      }
    }
  }
}

.page-item {
  &.active {
    .page-link {
      background-color: #d64343 !important;
      border-color: #9d1414 !important;
      color: white !important;
    }
  }

  &.disabled {
    .page-link {
      color: #e5bcbc !important;
    }
  }

  .page-link {
    color: #e10f0f !important;
  }
}

.actions {
  text-align: center;

  span {
    &:not(:first-child) {
      margin-left: 5px;
    }

    svg {
      font-size: 24px !important;
      cursor: pointer;
    }
  }
}
</style>
