<template>
  <b-container fluid>
    <h1>Profile</h1>

    <div v-if="user">
      <b-row>
        <b-col class="input-with-labels">
          <label for="firstname">First name:</label>
          <b-form-input v-model="user.firstname" id="firstname" disabled></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="lastname">Last name:</label>
          <b-form-input v-model="user.lastname" id="lastname" disabled></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="alternate_email">Email:</label>
          <b-form-input v-model="user.alternate_email" id="alternate_email" disabled></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="address1">Address 1:</label>
          <b-form-input v-model="user.address1" id="address1" disabled></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="address2">Address 2:</label>
          <b-form-input v-model="user.address2" id="address2" disabled></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="city">City:</label>
          <b-form-input v-model="user.city" id="city" disabled></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="state">State:</label>
          <b-form-input v-model="user.state.name_en" id="state" disabled></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="country">Country:</label>
          <b-form-input v-model="user.country.name_en" id="country" disabled></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="zipcode">Zip code:</label>
          <b-form-input v-model="user.zipcode" id="zipcode" disabled></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="tax_id">Tax id:</label>
          <b-form-input v-model="user.tax_id" id="tax_id" disabled></b-form-input>
        </b-col>
      </b-row>
    </div>

  </b-container>
</template>

<script>
export default {
  data () {
    return {
      user: null,
    }
  },
  created () {
    this.fetchUser()
  },
  methods: {
    fetchUser () {
      const self = this
      const loader = this.$loading.show();

      this.$http.get('/user')
      .then(function (response) {
        loader.hide()
        self.user = response.data
      })
      .catch(function (error) {
        loader.hide()
        console.log(error)
      })
    },
  }
}
</script>
