<template>
  <b-container fluid class="crud-modal">
    <div v-if="item">
      <b-row>
        <b-col class="input-with-labels">
          <label for="name">Name:</label>
          <b-form-input v-model="item.name" id="name" :disabled="editable == false" :class="{ 'hasError': !$v.item.name.required }"></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="email">Email:</label>
          <b-form-input v-model="item.email" id="email" :disabled="editable == false" :class="{ 'hasError': !$v.item.email.required || !$v.item.email.email }"></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="address1">Address 1:</label>
          <b-form-input v-model="item.address1" id="address1" :disabled="editable == false" :class="{ 'hasError': !$v.item.address1.required }"></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="address2">Address 2:</label>
          <b-form-input v-model="item.address2" id="address2" :disabled="editable == false"></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="country">Country:</label>
          <b-form-select v-model="item.country_id" id="country" @change="filterStates()"  :disabled="editable == false" :class="{ 'hasError': !$v.item.country_id.required }">
            <b-form-select-option value="">-- Select a country --</b-form-select-option>
            <b-form-select-option v-for="country in this.$root.countries" :value="country.id" v-bind:key="country.id">{{ country.name_en }}</b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="state">State:</label>
          <b-form-select v-model="item.state_id" id="state" :disabled="editable == false" :class="{ 'hasError': !$v.item.state_id.required }">
            <b-form-select-option value="">-- Select a state --</b-form-select-option>
            <b-form-select-option v-for="state in filteredStates" :value="state.id" v-bind:key="state.id">{{ state.name_en }}</b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="city">City:</label>
          <b-form-input v-model="item.city" id="city" :disabled="editable == false" :class="{ 'hasError': !$v.item.city.required }"></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="zipcode">Zip code:</label>
          <b-form-input v-model="item.zipcode" id="zipcode" :disabled="editable == false" :class="{ 'hasError': !$v.item.zipcode.required }"></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="input-with-labels">
          <label for="tax_id">Tax id:</label>
          <b-form-input v-model="item.tax_id" id="tax_id" :disabled="editable == false" :class="{ 'hasError': !$v.item.tax_id.required }"></b-form-input>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col class="input-with-labels">
          <label>Projects:</label>
          <b-form-group class="form-control project-checkboxes">
            <b-container fluid>
              <b-row class="header">
                <b-col>
                  Project name (Company)
                </b-col>

                <b-col cols="2">
                  Rate to Client
                </b-col>

                <b-col cols="2">
                  Rate to User
                </b-col>

                <b-col cols="2">
                  Fixed Rate
                </b-col>
              </b-row>

              <b-row v-for="(project, index) in item.projects" v-bind:key="index">
                <b-col>
                  <b-form-checkbox
                    v-model="project.checked"
                    value="true"
                    unchecked-value="false"
                    switch
                    size="lg"
                  >
                    {{ project.name }}
                  </b-form-checkbox>
                </b-col>

                <b-col cols="2">
                  <b-form-input placeholder="Rate To Client" v-model="project.rateToClient" :id="'rateToClient' + index" :disabled="editable == false" :class="{ 'hasError': !$v.item.projects.$each[index].rateToClient.decimal }"></b-form-input>
                </b-col>

                <b-col cols="2">
                  <b-form-input placeholder="Rate To User" v-model="project.rateToUser" :id="'rateToUser' + index" :disabled="editable == false" :class="{ 'hasError': !$v.item.projects.$each[index].rateToUser.decimal }"></b-form-input>
                </b-col>

                <b-col cols="2">
                  <b-form-checkbox
                    v-model="project.fixed_rate"
                    value="true"
                    unchecked-value="false"
                    switch
                    size="lg"
                  >
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-container>
          </b-form-group>
        </b-col>
      </b-row> -->
    </div>
  </b-container>
</template>

<script>
// import Vue from 'vue'
import { required, email, decimal } from 'vuelidate/lib/validators'

export default {
  props: {
    itemProp: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      item: this.itemProp,
      filteredStates: [],
      valid: false,
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      address1: {
        required
      },
      country_id: {
        required
      },
      state_id: {
        required
      },
      city: {
        required
      },
      zipcode: {
        required
      },
      tax_id: {
        required
      },
      projects: {
        $each: {
          rateToClient: {
            decimal
          },
          rateToUser: {
            decimal
          }
        }
      }
    }
  },
  watch: {
    item: {
      handler () {
        this.$emit('ready', !this.$v.item.$invalid)
      },
      deep: true
    },

    selectedProjects () {
      this.$emit('ready', !this.$v.item.$invalid)
    }
  },
  created () {
    this.filterStates(false)
    // this.fetchProjects()
  },
  methods: {
    filterStates (updateState = true) {
      this.filteredStates = this.$root.states.filter(el => el.country_id == this.item.country_id)
      if(updateState) {
        this.item.state_id = null
      }
    },
    //
    // fetchProjects () {
    //   const self = this
    //
    //   this.$http.get('/projects')
    //   .then(function (response) {
    //     Vue.set(self.item, 'projects', [])
    //
    //     response.data.forEach((project) => {
    //       let checked = false
    //       let rateToClient = 0
    //       let rateToUser = 0
    //       let fixed_rate = false
    //
    //       self.item.Projects.forEach((item) => {
    //         if(project.id == item.ProjectUser.projectId) {
    //           checked = true
    //           rateToClient = item.ProjectUser.rateToClient
    //           rateToUser = item.ProjectUser.rateToUser
    //           fixed_rate = item.ProjectUser.fixed_rate
    //         }
    //       })
    //
    //       self.item.projects.push({
    //         projectId: project.id,
    //         name: project.name + ' (' + project.client.name + ')',
    //         checked: checked,
    //         rateToClient: rateToClient,
    //         rateToUser: rateToUser,
    //         fixed_rate: fixed_rate,
    //       })
    //     })
    //   })
    // },
  }
}
</script>

<style lang="scss">
// .project-checkboxes {
//   .header {
//     font-weight: bold;
//   }
//
//   .container-fluid {
//     padding: 0;
//   }
//
//   .custom-control, label, input {
//     display: inline-block;
//   }
//
//   .custom-switch {
//     padding-top: 6px;
//
//     label {
//       padding-top: 3px !important;
//     }
//   }
//
//   label {
//     font-size: 16px !important;
//     font-weight: normal;
//   }
//
//   input {
//     width: 80px;
//   }
// }
//
// :disabled {
//   background-color: unset !important;
//   border: 0 !important;
// }
</style>
